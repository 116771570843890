import React from 'react';
import './App.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);


export default function App() {
  return (
    <Authenticator 
        loginMechanisms={['email']}
        hideSignUp={true}
    >
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.username} ({user.email})</h1>
          <button onClick={signOut}>Sign out</button>
        </main>
      )}
    </Authenticator>
  );
}