/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_dfemZ4MlC",
    "aws_user_pools_web_client_id": "7ibeuh7erqk6vj8j2o1q5buk1t",
    "oauth": {
        "domain": "auth.dreimann.net.auth.eu-central-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
